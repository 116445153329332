import { Injectable } from '@angular/core';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { NetworkService } from './network.service';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  public commentUrl = environment.apiUrl + '/comments';
  public latestGet: number;

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private databaseService: DatabaseService
  ) {
    this.getLatestGet().then(resp => this.latestGet = resp);
  }

  async getLatestGet(): Promise <number> {
    let latestGet = 0;
    if (this.databaseService.hasDatabase) {
      const sql = 'SELECT latestGet FROM comments LIMIT 1;';
      await this.databaseService.database.executeSql(sql, []).then(
        resp => {
          if (resp.rows.length > 0) {
            latestGet = JSON.parse(resp.rows.item(0).latestGet) as number;
          }
        }
      );
    }
    return (latestGet);
  }

  async getLocalComments(): Promise <RvdmComment[]> {
    let comments: RvdmComment[] = [];
    if (this.databaseService.hasDatabase) {
      const sql = 'SELECT * FROM comments';
      await this.databaseService.database.executeSql(sql, []).then(
        resp => {
          for (let i=0; i<resp.rows.length; i++) {
            comments.push(JSON.parse(resp.rows.item(i).comment) as RvdmComment);
          }
        }
      );
    }
    if (comments.length == 0) {
      // No process protocols so get them from online.
      comments = await lastValueFrom(this.getCommentsOnline());
    }
    comments.forEach(comment => {
      if (!comment.displayName) {
        comment.displayName =  comment.key + ' ' + comment.name;
      }
    })
    return (comments);
  }

  getComments(): Observable<RvdmComment[]> {
    return from(this.getLocalComments());
  }

  getCommentsOnline(): Observable<RvdmComment[]> {
    if (this.networkService.hasInternet) {
      return this.http.get<any>(this.commentUrl).pipe(
        map(resp => {
          const comments =  resp.comments as RvdmComment[];

          const current = new Date();
          this.updateCommentsInDatabase(comments, current.getTime());
          return comments;
        })
      );
    } else {
      return of ([]);
    }
  }

  updateCommentsInDatabase(comments: RvdmComment[], latestGet: number) {
    if (this.databaseService.hasDatabase) {
      this.latestGet = latestGet;
      let commentsQueries = [];
      commentsQueries.push(['DELETE FROM comments']);

      commentsQueries = commentsQueries.concat(
        comments.map(comment => ['INSERT INTO comments VALUES(?,?,?)', [comment.key, JSON.stringify(comment), latestGet]]));

      this.databaseService.database.sqlBatch(commentsQueries).then(() => console.log('Comments added in database'));
    }
  }
}

export interface RvdmComment {
  key: string;
  name: string;
  displayName: string;
}

