import {Relation, RelationLocation} from './relation.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject, from, lastValueFrom, Observable, of } from 'rxjs';
import {HttpClient, HttpEventType, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {DatabaseService} from './database.service';
import {NetworkService} from './network.service';
import {User, UserService} from './user.service';
import {Trailer, Truck} from './vehicle.service';
import {FertilizerCode, FertilizerService} from './fertilizer.service';
import {CommentService, RvdmComment} from './comment.service';
import {Lab} from './lab.service';
import { Transporter } from './transporter.service';
import { ProcessProtocol, ProcessprotocolService } from './processprotocol.service';
import { RejectionCode } from './rejection-code.service';
import { Photo } from '@capacitor/camera';
import { MasterDataSyncService } from './master-data-sync.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { DocumentRoutePosition } from './document-route.service';
import { AppService } from './app.service';


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  public reloadDocuments = new BehaviorSubject(false);
  public documentErrors: DocumentError[] = [];
  public latestAdmistatorDocumentsGet?: Date;
  public latestDriverDocumentsGet?: Date;
  public documentUrl = environment.apiUrl + '/documents';

  constructor(
    private http: HttpClient,
    private databaseService: DatabaseService,
    private networkService: NetworkService,
    private userService: UserService,
    private masterDataSyncService: MasterDataSyncService,
    private readonly router: Router,
    private readonly commentService: CommentService,
    private readonly processProtocolService: ProcessprotocolService,
    private readonly fertilizerService: FertilizerService,
    private loadingController: LoadingController,
    private readonly appService: AppService
  ) {
  }


  documentHasErrors(): boolean {
    if (this.documentErrors?.length) {
      return true;
    }
    return false;
  }

  clearDocumentErrors() {
    this.documentErrors = [];
  }

  shipmentRequest(document: RvdmDocument): Observable<RvdmDocument> {
    //ZendingAanvragen
    if (this.networkService.hasInternet) {
      return this.http.get<any>(`${this.documentUrl}/${document.id}/shipment_request`);
    }

    return of (void 0);
  }

  checkStatus(rvdmDocument: RvdmDocument): Observable<RvdmDocument> {
    if (this.networkService.hasInternet) {
      return this.http.get<any>(`${this.documentUrl}/${rvdmDocument.id}/check_status`);
    }
  }


  validationCheck(rvdmDocument: RvdmDocument): Observable<RvdmDocument> {
    if (this.networkService.hasInternet) {
      return this.http.get<any>(`${this.documentUrl}/${rvdmDocument.id}/validation_check`);
    }
  }

  changeRequest(rvdmDocument: RvdmDocument): Observable<RvdmDocument> {
    if (this.networkService.hasInternet) {
      const body = {
        rvdmDocument: JSON.stringify(rvdmDocument)
      };
      return this.http.post<any>(`${this.documentUrl}/${rvdmDocument.id}/change_request`, body);
    }
  }

  startNotification(document: RvdmDocument): Observable<RvdmDocument> {
    return this.http.get<any>(`${this.documentUrl}/${document.id}/start_notification`);
  }

  weighingNotification(document: RvdmDocument): Observable<RvdmDocument> {
    return this.http.get<any>(`${this.documentUrl}/${document.id}/weighing_notification`);
  }

  patchWeighingNotification(document: RvdmDocument): Observable<RvdmDocument> {
    const url = `${this.documentUrl}/${document.id}/weighing_notification`;

    const updateModel: UpdateWeighingNotification = {
      weighed_at: document.weighed_at,
      estimated_weight: document.estimated_weight,
      confirmed_estimated_weight: document.confirmed_estimated_weight,
      empty_weight: document.empty_weight,
      full_weight: document.full_weight,
      net_weight: document.net_weight,
      geo_location: {
        lat: document.geo_locations.weighed_pos.lat,
        long: document.geo_locations.weighed_pos.long
      }
    }

    return this.http.patch<RvdmDocument>(url, updateModel);
  }

  async clearOfflineWeights(): Promise<void> {
    return this.databaseService.database.executeSql('DELETE FROM offline_weights', []).then(
      resp => {
        return resp;
      }
    )
  }

  async weighedOffline(document: RvdmDocument, sendNotification: boolean, EmptyWeightImage: Photo, FullWeightImage: Photo): Promise<void> {
    if (sendNotification || EmptyWeightImage || FullWeightImage) {
      // When there is no notification to be send, nor a new picture, no need to create record.
      let empty_weight_image = null;
      let full_weight_image = null;

      if (EmptyWeightImage) {
        empty_weight_image = await this.photoAsBase64(EmptyWeightImage);
      }
      if (FullWeightImage) {
        full_weight_image = await this.photoAsBase64(FullWeightImage);
      }
      const values = JSON.stringify(document);
      return this.databaseService.database.executeSql('INSERT INTO offline_weights VALUES(?,?,?,?)', [values, sendNotification, empty_weight_image, full_weight_image]).then(
        resp => {
          return resp;
        }
      )
    }
  }

  public async convertImageToBlob(imagePath: string): Promise<Blob | null> {
    if (imagePath) {
      const response = await fetch(imagePath);
      return new Blob([await response.blob()], {
        type: 'image/jpeg'
      });
    } else {
      return null;
    }
  }

  async photoAsBase64(image: Photo): Promise<string> {
    const response = await fetch(image.webPath);
    const blob = await response.blob();

    const result =  await this.convertBlobToBase64(blob) as string;
    return result;

  }

  private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  async uploadImage(document: RvdmDocument, image: Photo, imageKind: DocumentImageKind): Promise<string> {
    let newImageUrl = '';
    const blob = await this.convertImageToBlob(image.webPath);
    await this.uploadBlobImage(document, blob, imageKind).then(doc => {
      newImageUrl = imageKind === DocumentImageKind.EmptyWeightImage ? doc.empty_weight_image : doc.full_weight_image;
    });
    return newImageUrl;
  }


  async uploadBlobImage(document: RvdmDocument, blobImage: Blob, imageKind: DocumentImageKind): Promise<RvdmDocument> {
    const fd = new FormData();
    let url = `${this.documentUrl}/${document.id}/image`;

    if (this.userService.currentUser.isRovecom() && this.userService.currentUser.transporter) {
      url = `${url}?transporter_id=${this.userService.currentUser.transporter.id.toString()}`;
    }

    fd.append('image', blobImage, imageKind+'.jpeg');
    fd.append('image_kind', imageKind);

    return lastValueFrom(this.http.post<RvdmDocument>(url, fd));
  }

  getDocumentVdmStatuses(document: RvdmDocument): Observable<DocumentVdmStatus[]> {
    if (!this.networkService.hasInternet) {
      return;
    }

    const url = `${this.documentUrl}/${document.id}/vdm_statuses`;
    return this.http.get<any>(url).pipe(
      map(resp => {
        return resp.vdm_statuses as DocumentVdmStatus[];
      })
    );
  }

  getDocument(id: string): Observable<RvdmDocument> {
    if (!this.networkService.hasInternet && this.databaseService.hasDatabase) {
      return this.getLocalDocument(+id);
    } else {
      return this.getRemoteDocument(id);
    }
  }

  getRemoteDocument(id: string): Observable<RvdmDocument> {
    return this.http.get<any>(`${this.documentUrl}/${id}`);
  }

  getLocalDocument(id: number, idType: string = 'id'): Observable<RvdmDocument> {
    if (!this.databaseService.hasDatabase) {
      return;
    }

    const sql = `SELECT *
                 FROM documents
                 WHERE documents.${idType} = ? LIMIT 1;`;
    const value = this.databaseService.database.executeSql(sql, [id]).then(
      resp => {
        if (resp.rows.length === 0) {
          return {} as RvdmDocument;
        }

        return JSON.parse(resp.rows.item(0).document) as RvdmDocument;
      }
    );
    return from(value);
  }

  createLocalDocument(rvdmDocument: RvdmDocument) {
    if (!this.databaseService.hasDatabase) {
      return;
    }

    return this.databaseService.database.executeSql('INSERT INTO documents VALUES(?,?,?,?,?,?)', [rvdmDocument.id, 'administrator', rvdmDocument.truck?.id, rvdmDocument.trailer?.id, JSON.stringify(rvdmDocument), !this.networkService.hasInternet]).then(
      resp => {
        console.log(`sql insert result: ${resp.rows}`);
        console.log(resp);
        return resp;
      }
    );
  }

  createRemoteDocument(rvdmDocument: RvdmDocument): Observable<RvdmDocument> {
    if (this.networkService.hasInternet) {
      const body = {
        rvdmDocument: JSON.stringify(rvdmDocument)
      };
      console.log(body);
      return this.http.post<any>(this.documentUrl, body).pipe(
        map(resp => {
          return resp;
        })
      );
    }
  }

  async updateDocument(rvdmDocument: RvdmDocument, partialDocument?: Partial<RvdmComment>): Promise<any> {
   await this.updateLocalDocument(rvdmDocument);

   if (rvdmDocument.id && partialDocument) {
    await lastValueFrom(this.updatePartialDocument(partialDocument, rvdmDocument));
   }
   else {
    await lastValueFrom(this.updateRemoteDocument(rvdmDocument));
   }
  }


  async patchOfflineChanges(): Promise<void> {
    if (!this.databaseService.hasDatabase || !this.networkService.hasInternet) {
      return;
    }
    const sql = `SELECT *
                 FROM offline_document_changes;`;

    this.databaseService.database.executeSql(sql, []).then(
      async resp => {
        if (resp.rows.length) {
          const loading = await this.loadingController.create({
            message: `Bijwerken ${resp.rows.length} document(en)...` ,
            cssClass: 'custom-loading',
          });
          loading.present();
          for (let i=0; i<resp.rows.length; i++) {
            const documentId =resp.rows.item(i).id as number;
            const document = JSON.parse(resp.rows.item(i).document) as RvdmDocument;
            const partialDocument = JSON.parse(resp.rows.item(i).partialDocument) as Partial<RvdmDocument>;
            if (document) {
              await lastValueFrom(this.updatePartialDocument(partialDocument, document));
              await this.clearOffDocumentChanges(documentId);
            }
          }
          loading.dismiss();
        }
        return of (void 0);
      }
    );
    // Done update documents. Now check for offline weight notifications
    await this.patchOfflineWeights();
    // Done offline Weights now check for offline routes
    await this.patchOfflineRoutes();
  }


  async patchOfflineRoutes(): Promise<void> {
    if (this.databaseService.hasDatabase && this.networkService.hasInternet) {
      const sql = `SELECT * FROM offline_route`;

      await this.databaseService.database.executeSql(sql, []).then(
        async resp => {
          if (resp.rows.length) {
            // There are offline routes
            console.log(`Sending ${resp.rows.length} offline routes`);
            console.log(resp)
            for (let i=0; i<resp.rows.length; i++) {
              const documentId =resp.rows.item(i).id as number;
              const route = JSON.parse(resp.rows.item(i).route) as DocumentRoutePosition[];

              const body = {
                positions: route
              };
              await lastValueFrom(this.http.post<any>(`${this.documentUrl}/${documentId}/route`, body)).then(async () =>
                  await this.clearOfflineRoute(documentId)
              );
            }
          }
        }
      )
    }
  }

  async clearOffDocumentChanges(documentId: number): Promise<void> {
    return this.databaseService.database.executeSql('DELETE FROM offline_document_changes where id = ?', [documentId]).then(
      resp => {
        return resp;
      }
    )
  }

  async clearOfflineRoute(documentId: number): Promise<void> {
    return this.databaseService.database.executeSql('DELETE FROM offline_route where id = ?', [documentId]).then(
      resp => {
        return resp;
      }
    )
  }

  patchOfflineWeights(): Promise<void> {
    if (!this.databaseService.hasDatabase || !this.networkService.hasInternet) {
      return;
    }
    const sql = `SELECT * FROM offline_weights`;

    this.databaseService.database.executeSql(sql, []).then(
      async resp => {

        if (resp.rows.length) {
          // To avoid flashing loading screens only show this when there is data to process.
          const loading = await this.loadingController.create({
            message: 'Bijwerken weegmeldingen/fotos...',
            cssClass: 'custom-loading',
          });
          loading.present();

          for (let i=0; i<resp.rows.length; i++) {
            let document = JSON.parse(resp.rows.item(i).document) as RvdmDocument;
            if (document.id) {
              if (resp.rows.item(i).send_notification) {
                await lastValueFrom(this.patchWeighingNotification(document))
                  .then(async doc => {
                    // doc is the latest version of the document on the server so save that locally.
                    await this.updateLocalDocument(doc);
                    })
                  .catch(e => {
                    alert(e.message);
                  });
              }

              const emptyWeightImage = resp.rows.item(i).empty_weight_image;
              if (emptyWeightImage) {
                this.b64toBlob(emptyWeightImage).then(async blob => {
                  await this.uploadBlobImage(document, blob, DocumentImageKind.EmptyWeightImage).then(async doc => {
                    // doc is the latest version of the document on the server so save that locally.
                    await this.updateLocalDocument(doc);
                  });
                });
              }
              const fullWeightImage = resp.rows.item(i).full_weight_image;
              if (fullWeightImage) {
                this.b64toBlob(fullWeightImage).then(async blob => {
                  await this.uploadBlobImage(document, blob, DocumentImageKind.FullWeightImage).then(async doc => {
                    // doc is the latest version of the document on the server so save that locally.
                    await this.updateLocalDocument(doc);
                  });
                });
              }
            }
          }
          this.clearOfflineWeights();
          loading.dismiss();
        }
        return of (void 0);
      }
    );
  }

  async b64toBlob(dataURI): Promise<Blob> {

    let byteString = atob(dataURI.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  updateLocalDocument(rvdmDocument: RvdmDocument): Promise<void> {
    if (!this.databaseService.hasDatabase) {
      return;
    }

    const values = JSON.stringify(rvdmDocument);
    const updatedOffline = !this.networkService.hasInternet;
    return this.databaseService.database.executeSql('UPDATE documents SET document = ?,  updatedOffline = ? WHERE id = ?', [values, updatedOffline, rvdmDocument.id]).then(
      resp => {
        console.log(`sql result: ${resp}`);
        return resp;
      }
    );
  }

  convertToPatchDocument(rvdmDocument: Partial<RvdmDocument>): RvdmPatchDocument {
    const rvdmPatchDocument: RvdmPatchDocument = {};

    for (const key in rvdmDocument) {
      switch( key ) {
        case 'user': rvdmPatchDocument.user_id = +rvdmDocument.user.id; break;
        case 'fertilizer_code_1': rvdmPatchDocument.fertilizer_key_1 = rvdmDocument.fertilizer_code_1.code; break;
        case 'fertilizer_code_2': rvdmPatchDocument.fertilizer_key_2 = rvdmDocument.fertilizer_code_2.code; break;
        case 'fertilizer_code_3': rvdmPatchDocument.fertilizer_key_3 = rvdmDocument.fertilizer_code_3.code; break;
        case 'fertilizer_code_4': rvdmPatchDocument.fertilizer_key_4 = rvdmDocument.fertilizer_code_4.code; break;
        case 'comment_1': rvdmPatchDocument.comment_key_1 = rvdmDocument.comment_1.key; break;
        case 'comment_2': rvdmPatchDocument.comment_key_2 = rvdmDocument.comment_2.key; break;
        case 'comment_3': rvdmPatchDocument.comment_key_3 = rvdmDocument.comment_3.key; break;
        case 'comment_4': rvdmPatchDocument.comment_key_4 = rvdmDocument.comment_4.key; break;
        case 'trailer': rvdmPatchDocument.trailer_id = rvdmDocument.trailer.id; break
        case 'truck': rvdmPatchDocument.truck_id = rvdmDocument.truck.id; break
        case 'client': rvdmPatchDocument.client_id = +rvdmDocument.client.id; break;
        case 'client_location': rvdmPatchDocument.client_location_id = +rvdmDocument.client_location.id; break;
        case 'supplier': rvdmPatchDocument.supplier_id = +rvdmDocument.supplier.id; break;
        case 'supplier_location': rvdmPatchDocument.supplier_location_id = +rvdmDocument.supplier_location.id; break;
        case 'other': rvdmPatchDocument.other_id = +rvdmDocument.other.id; break;
        case 'lab': rvdmPatchDocument.lab_key = rvdmDocument.lab.key; break;
        case 'process_protocol': rvdmPatchDocument.process_protocol_code = rvdmDocument.process_protocol.code.toString(); break;
        default: rvdmPatchDocument[key] = rvdmDocument[key];
      }
    }
    return rvdmPatchDocument;
  }

  updatePartialDocument(rvdmDocument: Partial<RvdmDocument>, document: RvdmDocument): Observable<RvdmDocument> {
    if (!this.networkService.hasInternet) {
      // Save patial locally for later
      return from( this.databaseService.database.executeSql('INSERT INTO offline_document_changes VALUES(?,?,?)', [document.id, JSON.stringify(document), JSON.stringify(rvdmDocument)]));
    }
    const rvdmPatchDocument = this.convertToPatchDocument(rvdmDocument);
    const url = `${this.documentUrl}/${document.id}`;
    return this.http.patch<RvdmDocument>(url, rvdmPatchDocument).pipe(
      tap(resp => {
        console.log('partial update remotely');
        console.log(resp);
      })
    );
  }

  updateRemoteDocument(rvdmDocument: RvdmDocument): Observable<RvdmDocument> {
    if (!this.networkService.hasInternet) {
      return of();
    }
    const body = {
      rvdmDocument: JSON.stringify(rvdmDocument)
    };
    const url = `${this.documentUrl}/${rvdmDocument.id}`;
    return this.http.put<any>(url, body).pipe(
      tap(resp => {
        console.log('update remotely');
        console.log(resp);
      })
    );
  }


  documentStoppedDriving(document: RvdmDocument): boolean {
    // Check if document is stoped driving.
    return document.key ? document.stop_driving_at !== null : false;
  }

  documentUnloaded(document: RvdmDocument): boolean {
    // Check if document is unloaded.
    // When the process protocoll says it requires gr vehicle document is unloaded when unload message is received.
    // Else document is unloaded when driver completed the document.
    if (!document.key) {
      // When the document has no key (new) it can never be unloaded.
      return false;
    }
    if (document.process_protocol.require_gr_vehicle) {
      return document.unloaded_at !== null || document.geo_locations.unloaded_pos.lat !==null
    }
    return document.stop_driving_at !== null;
  }


  documentStatusIs12OrLess(document: RvdmDocument): boolean {
    if (!document.vdm_status) {
      //No status so must be less then 12
      return true;
    }
    if (!document.vdm_status.status_code) {
      //No status-code so must be less then 12'
      return true;
    }
    const status = +document.vdm_status.status_code;
    return  (status <= 12);
  }

  documentEditableBasedOnStatusCode(document: RvdmDocument): boolean {
    const uneditableStatuscodes: string[] = ['11', '13', '16', '17', '18'];
    if (!document.vdm_status) {
      //No status so editable.
      return true;
    }
    if (!document.vdm_status.status_code) {
      //No status-code so editable.
      return true;
    }
    return !uneditableStatuscodes.includes(document.vdm_status.status_code)
  }


  checkForNewMasterData() {
    this.appService.checkForNotifications();
    this.masterDataSyncService.checkForNewMasterData().subscribe(resp => {
      if (resp) {
        const current = new Date();
        const getTime = current.getTime();
        if (resp.comments) {
          this.commentService.updateCommentsInDatabase(resp.comments, getTime);
        }
        if (resp.fertilizer_codes) {
          this.fertilizerService.updateFertilizersInDatabase(resp.fertilizer_codes, getTime);
        }
        if (resp.process_protocols) {
          this.processProtocolService.updateProcessProtocolsInDatabase(resp.process_protocols, getTime);
        }
      }
    });
  };


  getDocuments(kind: string, truck?: Truck, trailer?: Trailer): Observable<RvdmDocument[]> {
    console.log('get documents');
    this.checkForNewMasterData();
    this.patchOfflineChanges();
    this.clearDocumentErrors();
    if (this.networkService.hasInternet) {
      console.log(`from api for kind: ${kind}`);
      let params = new HttpParams().set('kind', kind);
      if (truck) {
        params = params.set('truck_id', truck.id.toString());
      }
      if (trailer) {
        params = params.set('trailer_id', trailer.id.toString());
      }
      return this.http.get <any>(this.documentUrl, {params}).pipe(
        tap(resp => {
          const documents = resp as RvdmDocument[];
          if (this.databaseService.hasDatabase) {
            let documentQueries = [];
            documentQueries.push([`DELETE
                                   FROM documents
                                   WHERE kind = "${kind}"`]);
            documentQueries = documentQueries.concat(documents.map(document => ['INSERT INTO documents VALUES (?,?,?,?,?,?)', [document.id, kind, document.truck?.id, document.trailer?.id, JSON.stringify(document), !this.networkService.hasInternet]]));
            this.databaseService.database.sqlBatch(documentQueries).then().catch(e => {console.error(e)});
          }
        })
      );
    } else {
      return from(this.getLocalDocuments(kind));
    }
  }

  async getLocalDocuments(kind: string): Promise<RvdmDocument[]> {
    console.log('from database');
    const documents: RvdmDocument[] = [];
    if (this.databaseService.hasDatabase) {
      await this.databaseService.database.executeSql(`SELECT *
                                                    FROM documents
                                                    WHERE kind = ? ORDER BY id DESC`, [kind]).then(res => {

        const len = res.rows.length;
        for (let i = 0; i < len; i++) {
          documents.push(JSON.parse(res.rows.item(i).document));
        }
      });

    }
    return documents;
  }

  addDocumentError(message: string, isDetailRow: boolean) {
    const docError: DocumentError = {
        message: message,
        isDetailRow: isDetailRow} ;

    this.documentErrors.push(docError);
  }


  copyDocument(documentId: string): Observable<RvdmDocument> {
    if (this.networkService.hasInternet) {
      return this.http.get<any>(`${this.documentUrl}/${documentId}/copy_document`);
    }
  }

  async copyToNewDocument(document: RvdmDocument) {
    await this.router.navigate(['/document/new'], { queryParams: { copyFromId: document.id } });
  }

  async goToDriversDocumentList() {
    await this.router.navigate(['/tabs/driver-documents']);
  }

}

export interface UpdateWeighingNotification {
  weighed_at?: string;
  estimated_weight?: number;
  confirmed_estimated_weight?: number;
  empty_weight?: number;
  full_weight?: number;
  net_weight?: number;
  geo_location?: {
    lat?: string,
    long?: string
  }
}

export interface RvdmPatchDocument {
  id?: number;
  key?: string;
  export_worthy?: boolean;
  barcode_displayed?: boolean;
  request_key?: number;
  client_id?: number;
  supplier_id?: number;
  other_id?: number;
  client_location_id?: number;
  supplier_location_id?: number;
  estimated_start?: string;
  estimated_start_time?: string;
  has_estimated_start_time?: boolean
  estimated_weight?: number;
  start_at?: string;
  confirmed_at?: string;
  stop_driving_at?: string;
  fertilizer_key_1?: string;
  fertilizer_key_2?: string;
  fertilizer_key_3?: string;
  fertilizer_key_4?: string;
  fertilizer_percentage_1?: number;
  fertilizer_percentage_2?: number;
  fertilizer_percentage_3?: number;
  fertilizer_percentage_4?: number;
  truck_id?: number;
  user_id?: number;
  trailer_id?: number;
  loaded_at?: string;
  comment_key_1?: string;
  comment_key_2?: string;
  comment_key_3?: string;
  comment_key_4?: string;
  transporter_id?: number;
  weighed_at?: string;
  geo_locations?: {
    started_pos?: {
      lat?: string,
      long?: string
    }
    stopped_pos?: {
      lat?: string,
      long?: string
    }
    weighed_pos?: {
      lat?: string,
      long?: string
    }
    loaded_pos?: {
      lat?: string,
      long?: string
    }
    unloaded_pos?: {
      lat?: string,
      long?: string
    }
  };
  seal_key?: string;
  sample_key?: string;
  net_weight?: number;
  full_weight?: number;
  full_weight_image?: string;
  empty_weight?: number;
  empty_weight_image?: string;
  lab_key?: string;
  process_protocol_code?: string;
  confirmed_estimated_weight?: number;
  unloaded_at?: string;
  is_confirmed?: boolean;
  phosphate?: number,
  nitrogen?: number,
  own_code?: string;
}

export interface RvdmDocument {
  id: number;
  key: string;
  export_worthy: boolean;
  barcode_displayed: boolean;
  request_key: number;
  client: Relation;
  supplier: Relation;
  other: Relation;
  transporter: Transporter;
  client_location: RelationLocation;
  supplier_location: RelationLocation;
  estimated_start: string;
  estimated_start_time: string;
  has_estimated_start_time: boolean
  estimated_weight: number;
  start_at: string;
  confirmed_at: string;
  stop_driving_at: string;
  fertilizer_code_1: FertilizerCode;
  fertilizer_code_2: FertilizerCode;
  fertilizer_code_3: FertilizerCode;
  fertilizer_code_4: FertilizerCode;
  fertilizer_percentage_1: number;
  fertilizer_percentage_2: number;
  fertilizer_percentage_3: number;
  fertilizer_percentage_4: number;
  truck: Truck;
  user: User;
  trailer?: Trailer;
  loaded_at: string;
  comment_1: RvdmComment;
  comment_2: RvdmComment;
  comment_3: RvdmComment;
  comment_4: RvdmComment;
  transporter_id: number;
  weighed_at: string;
  geo_locations: {
    started_pos: {
      lat?: string,
      long?: string
    }
    stopped_pos: {
      lat?: string,
      long?: string
    }
    weighed_pos: {
      lat?: string,
      long?: string
    }
    loaded_pos: {
      lat?: string,
      long?: string
    }
    unloaded_pos: {
      lat?: string,
      long?: string
    }
  };
  seal_key: string;
  sample_key: string;
  net_weight: number;
  full_weight: number;
  full_weight_image: string;
  empty_weight: number;
  empty_weight_image: string;
  lab: Lab;
  eed_status: DocumentStatus;
  vdm_status?: DocumentVdmStatus;
  process_protocol: ProcessProtocol;
  confirmed_estimated_weight: number;
  unloaded_at: string;
  is_confirmed: boolean;
  alerts?: Alert[],
  phosphate: number,
  nitrogen: number,
  own_code: string;
  cm: boolean,
  cm_document_certificates: CmDocumentCertificate[]
}

export interface DocumentStatus {
  code: string;
  message: string;
}

export interface DocumentVdmStatus {
  client_approved: boolean,
  client_approved_start_date?: string,
  client_rejections?: RejectionCode[],
  client_rejection_reason?: string,
  status_code: string,
  status_message: string,
  supplier_approved: boolean,
  supplier_approved_start_date?: string,
  supplier_rejections?: RejectionCode[],
  supplier_rejection_reason?: string,
  created_at: string
}

export enum AlertCause {
  EcertAgrLoading = 'ecert_agr_loading',
  EcertAgrUnloading = 'ecert_agr_unloading'
}

export enum AlertSolution {
  ManualStartTransport = 'manual_start_transport',
  ManualEndTransport = 'manual_end_transport'
}

export enum DocumentImageKind {
  EmptyWeightImage = 'empty_weight_image',
  FullWeightImage = 'full_weight_image'
}

export interface Alert {
  cause?: AlertCause,
  cause_description?: string,
  solution?: AlertSolution,
  solution_description?: string
}

export interface DocumentError {
  message: string,
  isDetailRow: boolean;
}

export interface CmDocumentCertificate {
  id: number;
  name: string;
  url: string;
}
