// @ts-ignore
import pkg from '../../package.json';
const version = pkg.version;

export const environment = {
  production: false,
  environment: 'integration',
  version,
  apiUrl: 'https://evdm.rovecomdemo.nl/api/app',
  resetPasswordUrl: 'https://evdm.rovecomdemo.nl/password/new',
  authConfig: {
    host: 'https://accounts.rovecomdemo.nl',
    realm: 'rovecom-data-services',
    clientId: 'evdm',
    showDebugInformation: true,
    requireHttps: false
  },
  data2TrackPackageName: 'com.data2track.drivers'
};
